import React, { useEffect, useMemo, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Grid } from '@mui/material';
import './index.css';
import Snackbar from '../components/snackbar/index';
import LoginModal from '../pages/user-account/user-login';
import SignUpModal from '../pages/user-account/user-signup';
import ResetModal from '../pages/user-account/user-password';
import ResetPassModal from '../pages/user-account/user-password/resetPassword';
import { refreshToken } from '../utils/axiosClient';
import OTPModal from '../pages/partner-auth-page/OtpModal';

function Login({
	loginForm,
	children,
	userEmail,
	newUser,
	partnerName,
	hashedPath
}) {
	const [openLogin, setOpenLogin] = useState(false);
	const [openSignUp, setOpenSignUp] = useState(false);
	const [openOtp, setOpenOtp] = useState(false);
	const [resetEmail, setResetEmail] = useState('');
	const [openReset, setOpenReset] = useState(false);
	const [openSetPassword, setOpenSetPassword] = useState(false);
	const [loader, setLoader] = useState(false);
	const [openSnack, setOpenSnack] = useState(false);
	const [snackMessage, setSnackMessage] = useState();
	const [snackColor, setSnackColor] = useState(false);
	const [lastEmail, setLastEmail] = useState();
	const partnerAuth = useMemo(
		() => window.location.pathname.includes('/login/partner/'),
		[window.location.pathname]
	);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const timeOut =
			urlParams.has('error_description') || urlParams.has('code') ? 4000 : 200;
		// organization invites shouldn't have login modal popping up
		if (window.location.href.includes('/organization')) {
			if (
				urlParams.has('email') &&
				urlParams.has('orgid') &&
				urlParams.has('invite')
			) {
				setOpenLogin(false);
				return;
			}
		}
		setTimeout(async () => {
			if (
				!refreshToken && // refresh token referenced in axiosClient which is taken from cookie is mainly controlling the auth modal pop up
				!window.location.pathname.includes('terms-and-conditions') &&
				!window.location.pathname.includes('privacy-policy') &&
				!window.location.pathname.includes('cookie-policy')
			) {
				if (newUser) {
					handleSignUp();
				} else if (newUser === false && loginForm === true) {
					setOpenLogin(true);
				} else {
					setOpenLogin(true);
				}
				localStorage.removeItem('notGoogle');
			}
		}, timeOut);
	}, []);

	const childToParent = (resetEmail) => {
		setResetEmail(resetEmail);
	};

	const handleLogin = () => {
		setOpenLogin(true);
		setOpenSignUp(false);
		setOpenSetPassword(false);
		setOpenReset(false);
	};

	const handleSignUp = () => {
		setOpenSignUp(true);
		setOpenLogin(false);
	};

	const handleSignUpClose = () => setOpenSignUp(false);

	const handleResetPass = () => {
		setOpenReset(true);
		setOpenLogin(false);
		setOpenSetPassword(false);
	};

	const handleOpenSetPassword = () => {
		setOpenSetPassword(true);
		setOpenReset(false);
	};

	const handleXanaduLogin = (emailid) => {
		setLastEmail(emailid);
		setOpenOtp(true);
		setOpenLogin(false);
	};

	return (
		<Grid container gridArea="body">
			{children}
			<Grid item xl={12} lg={12} md={12} sm={12} xs={12} order={{ xs: 1 }}>
				<Grid container className="page-layout">
					<LoginModal
						loader={loader}
						setLoader={setLoader}
						openLogin={openLogin}
						setOpenLogin={setOpenLogin}
						handleSignUp={handleSignUp}
						handleResetPass={handleResetPass}
						handleXanaduLogin={handleXanaduLogin}
						partnerAuth={partnerAuth}
						userEmail={userEmail || lastEmail}
					/>
					<SignUpModal
						openSignUp={openSignUp}
						handleSignUpClose={handleSignUpClose}
						handleLogin={handleLogin}
						setOpenLogin={setOpenLogin}
						setLastEmail={setLastEmail}
						setOpenSignUp={setOpenSignUp}
						partnerAuth={partnerAuth}
						userEmail={userEmail}
					/>
					<ResetModal
						openReset={openReset}
						handleLogin={handleLogin}
						handleResetPass={handleResetPass}
						handleOpenSetPassword={handleOpenSetPassword}
						childToParent={childToParent}
						partnerAuth={partnerAuth}
					/>
					<ResetPassModal
						openSetPassword={openSetPassword}
						handleResetPass={handleResetPass}
						setOpenLogin={setOpenLogin}
						resetEmail={resetEmail}
						partnerAuth={partnerAuth}
					/>
					<OTPModal
						open={openOtp}
						email={userEmail || lastEmail}
						partnerName={partnerName}
						hashedPath={hashedPath}
					/>
					<Snackbar
						openSnack={openSnack}
						snackMessage={snackMessage}
						snackColor={snackColor}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default Login;
