import {
	ADD_TO_ACTIVE_USER_LOADING_ORGS,
	CREATE_ORG_FAILED,
	CREATE_ORG_LOADING,
	CREATE_ORG_LOADING_STOP,
	CREATE_ORG_SUCCESS,
	FETCH_ORG_USER_LIST_SUCCESS,
	FETCH_ORGLIST_FAILED,
	FETCH_ORGLIST_LOADING,
	FETCH_ORGLIST_SUCCESS,
	ORGLIST_LOADING_STOP,
	REMOVE_FROM_ACTIVE_USER_LOADING_ORGS,
	REMOVE_ORG_USERS_FROM_LIST,
	RESET_ORG_STATE,
	SET_MAIN_TAB_VALUE,
	SET_ORGANIZATION_TRANSACTION_FAILURE,
	SET_ORGANIZATION_TRANSACTION_LOADING,
	SET_ORGANIZATION_TRANSACTION_SUCCESS,
	SET_PAGINATION_DETAILS_FOR_ORG_USERS,
	SET_PURCHASE_TAB_VALUE,
	SET_SELECTED_ORG,
	SET_SELECTED_ORG_CURRENT_USER_DEDUCTION_REQUEST,
	SET_SELECTED_ORG_CURRENT_USER_ROLE,
	SET_USER_INVITE_LIST
} from '../Types/organizationTypes';

// Subs are subscriptions
const initialState = {
	orgList: [],
	orgUsers: {}, // {orgId: {users: [...], pagination: {currentPage: number, limit: number, totalPages: number, totalUsers: number}}, orgId: {users: [users], pagination: {} }, ...}
	isLoading: false,
	success: false,
	createOrgLoading: false,
	createOrgStatus: 'none', // 'success' | 'failure' | 'none'
	invitedUsersList: [], // [{email, role, status: 'pending'|'loading'|'success'|'failure'}]
	organizationUserLoading: false,
	activeOrgsLoadingUsers: [],
	selectedOrg: {},
	currentUserRole: '',
	deductionRequest: {
		status: 'none',
		creditAmount: 0,
		requestedAt: ''
	},
	orgMainTabs: 0,
	orgPurchaseTabs: 0,
	orgTransactions: {}, // {orgId: {transactions: [...], pagination: {currentPage: number, limit: number, totalPages: number, totalTransactions: number}}, orgId: {transactions: [...], pagination: {} }, ...}
	transactionLoading: false,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_MAIN_TAB_VALUE: {
			return {
				...state,
				orgMainTabs: action.payload
			};
		}
		case SET_PURCHASE_TAB_VALUE: {
			return {
				...state,
				orgPurchaseTabs: action.payload
			};
		}
		case FETCH_ORGLIST_LOADING: {
			return {
				...state,
				isLoading: true
			};
		}
		case FETCH_ORGLIST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				success: true,
				orgList: action.payload
			};
		}
		case FETCH_ORGLIST_FAILED: {
			return {
				...state,
				isLoading: false,
				success: false,
				orgList: []
			};
		}
		case ORGLIST_LOADING_STOP: {
			return {
				...state,
				isLoading: false
			};
		}
		case ADD_TO_ACTIVE_USER_LOADING_ORGS: {
			return {
				...state,
				organizationUserLoading: true
			};
		}
		case REMOVE_FROM_ACTIVE_USER_LOADING_ORGS: {
			return {
				...state,
				organizationUserLoading: false
			};
		}
		case FETCH_ORG_USER_LIST_SUCCESS: {
			const { orgId, forceRefresh } = action.payload;
			if (forceRefresh) {
				return {
					...state,
					orgUsers: {
						...state.orgUsers,
						[orgId]: {
							users: action.payload.orgUsers,
							pagination: action.payload.orgUserPagination
						}
					}
				};
			}
			const existingUsers = state.orgUsers[orgId]?.users || [];
			let newUsers = action.payload.orgUsers;
			if (existingUsers.length > 0) {
				newUsers = action.payload.orgUsers.filter(
					(users) => !existingUsers.some((user) => user._id === users._id)
				);
			}
			return {
				...state,
				orgUsers: {
					...state.orgUsers,
					[orgId]: {
						users: [...existingUsers, ...newUsers],
						pagination: action.payload.orgUserPagination
					}
				}
			};
		}
		case SET_PAGINATION_DETAILS_FOR_ORG_USERS: {
			const { orgId, page, limit } = action.payload;
			return {
				...state,
				orgUsers: {
					...state.orgUsers,
					[orgId]: {
						...state.orgUsers[orgId],
						pagination: {
							...state.orgUsers[orgId].pagination,
							currentPage: page,
							limit
						}
					}
				}
			};
		}
		case REMOVE_ORG_USERS_FROM_LIST: {
			const { orgId } = action.payload;
			const { [orgId]: removed, ...rest } = state.orgUsers;
			return {
				...state,
				orgUsers: rest
			};
		}
		case SET_SELECTED_ORG: {
			return {
				...state,
				selectedOrg: action.payload
			};
		}
		case SET_SELECTED_ORG_CURRENT_USER_ROLE: {
			return {
				...state,
				currentUserRole: action.payload
			};
		}

		case SET_SELECTED_ORG_CURRENT_USER_DEDUCTION_REQUEST: {
			return {
				...state,
				deductionRequest: action.payload
			};
		}

		case CREATE_ORG_LOADING: {
			return {
				...state,
				createOrgLoading: true
			};
		}
		case CREATE_ORG_SUCCESS: {
			return {
				...state,
				createOrgStatus: 'success'
			};
		}
		case CREATE_ORG_LOADING_STOP: {
			return { ...state, createOrgLoading: false };
		}
		case CREATE_ORG_FAILED: {
			return {
				...state,
				createOrgLoading: false,
				createOrgStatus: 'failure'
			};
		}

		case SET_USER_INVITE_LIST: {
			const updatedInvitedUsersList = state.invitedUsersList.map((user) =>
				user.email === action.payload.email
					? { ...user, status: action.payload.status }
					: user);
			const userExists = state.invitedUsersList.some(
				(user) => user.email === action.payload.email
			);
			return {
				...state,
				invitedUsersList: userExists
					? updatedInvitedUsersList
					: [...state.invitedUsersList, action.payload]
			};
		}

		case RESET_ORG_STATE: {
			return {
				...state,
				invitedUsersList: [],
				createOrgStatus: 'none',
				isLoading: false,
				success: false
			};
		}

		case SET_ORGANIZATION_TRANSACTION_LOADING: {
			return { ...state, transactionLoading: true };
		}

		case SET_ORGANIZATION_TRANSACTION_SUCCESS: {
			const org = state.orgList.find(
				(o) => o.org.organization.orgEmail === action.payload.orgEmail
			);
			if (org) {
				const orgId = org.org.organization._id;
				return {
					...state,
					transactionLoading: false,
					orgTransactions: {
						...state.orgTransactions,
						[orgId]: {
							transactions: action.payload.transactions,
							pagination: action.payload.pagination
						}
					}
				};
			}
			return state;
		}
		case SET_ORGANIZATION_TRANSACTION_FAILURE: {
			return { ...state, transactionLoading: false };
		}

		default:
			return {
				...state
			};
	}
}
