export const lightThemePalette = {
	mode: 'light',
	primary: {
		main: '#9b12df'
	},
	secondary: {
		main: '#560AA3'
	},
	brandColor: {
		400: '#df0982'
	},
	background: {
		alternate: 'linear-gradient(120deg, #fdfbfb 0%, #f2eff5 100%)',
		light: '#F2EFF5'
	},
	text: {
		auth: {
			login100: '#2E3338',
			signup100: '#414345',
			signup200: '#2e3338',
			forget100: '#444444',
			forget200: '#555555'
		}
	}
};

export const darkThemePalette = {
	mode: 'dark',
	primary: {
		main: '#eb2cff'
	},
	secondary: {
		main: '#560AA3'
	},
	brandColor: {
		400: '#df0982'
	},
	background: {
		alternate: '#1e1e1e',
		light: '#333036'
	},
	text: {
		auth: {
			login100: '#c2c2c2',
			signup100: '#c1c7cd',
			signup200: '#dddddd',
			forget100: '#cecece',
			forget200: '#b6b6b6'
		}
	}
};
