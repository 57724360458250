import { useState } from 'react';
import { useDispatch } from 'react-redux';
import OTPInput from 'otp-input-react';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import {
	Dialog,
	DialogContent,
	Grid,
	TextField,
	useMediaQuery,
	useTheme
} from '@mui/material';
import ModalDesc from '../modal-desc';
import { UserSubmitNewPasswordFunction } from '../../../Redux/Action/userAction';
import { currentDomain } from '../../../utils/domainBasedInfo/currentDomain';
import './resetPassword.css';
import { setSnackbarMsg } from '../../../Redux/Action/loadingErrMsgAction';

export default function ResetPassModal({
	openSetPassword,
	handleResetPass,
	resetEmail,
	partnerAuth
}) {
	const dispatch = useDispatch();
	const [OTP, setOTP] = useState('');
	const muiTheme = useTheme();

	const validationSchema = yup.object({
		newPassword: yup
			.string('Enter your password')
			.min(8, 'Password should be of minimum 8 characters length')
			.min(8, 'Must be 8 characters or more')
			.matches(/[a-z]+/, 'One lowercase character')
			.matches(/[A-Z]+/, 'One uppercase character')
			.matches(/[@$!%*#?&]+/, 'One special character')
			.matches(/\d+/, 'One number'),
		confirmNewPassword: yup
			.string('Enter your password')
			.required('Password is required')
			.oneOf([yup.ref('newPassword')], 'Your passwords do not match.')
			.min(8, 'Must be 8 characters or more')
			.matches(/[a-z]+/, 'One lowercase character')
			.matches(/[A-Z]+/, 'One uppercase character')
			.matches(/[@$!%*#?&]+/, 'One special character')
			.matches(/\d+/, 'One number')
	});
	const formik = useFormik({
		initialValues: {
			newPassword: '',
			confirmNewPassword: ''
		},
		validationSchema,
		onSubmit: async (values) => {
			const res = await dispatch(
				UserSubmitNewPasswordFunction(resetEmail, OTP, values.newPassword)
			);
			if (res === 'Success') {
				dispatch(setSnackbarMsg({ message: 'Password Successfully Updated', color: "success" }));
			} else {
				dispatch(setSnackbarMsg({ message: res.toString(), color: "error" }));
			}
		}
	});
	return (
		<Dialog
			open={openSetPassword}
			maxWidth="fit-content"
			fullScreen={useMediaQuery(muiTheme.breakpoints.down('sm'))}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			className="login-modal"
		>
			<DialogContent sx={{ p: 0, maxWidth: 800 }}>
				<form onSubmit={formik.handleSubmit}>
					<Grid container>
						<Grid
							item
							container
							lg={6}
							md={0}
							sm={0}
							xs={0}
							sx={{
								display: {
									xs: 'none',
									sm: 'none',
									md: 'none',
									lg: 'flex'
								}
							}}
							justifyContent="center"
							className="imgGrid"
						>
							<ModalDesc
								title={`Join ${
									partnerAuth ? 'QHack' : currentDomain.title
								} for free`}
								desc={
									partnerAuth
										? 'QHack is a world-renowned annual quantum hackathon that takes your education to the next level'
										: currentDomain.loginModal.description
								}
							/>
						</Grid>
						<Grid
							container
							item
							md={12}
							lg={6}
							sm={12}
							xs={12}
							my={4}
							sx={(theme) => ({
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								padding: '1rem',
								[theme.breakpoints.down(916)]: {
									maxHeight: '100vh',
									overflow: 'auto'
								}
							})}
						>
							<Typography
								className="reset-headtext"
								sx={{ color: muiTheme.palette.text.auth.login100 }}
							>
								Reset Password
							</Typography>
							<Typography
								className="password-text"
								textAlign="center"
								width="100%"
								sx={{ color: muiTheme.palette.text.auth.forget200 }}
							>
								Enter the security code from your email
							</Typography>
							<OTPInput
								value={OTP}
								onChange={setOTP}
								autoFocus
								OTPLength={6}
								otpType="number"
								disabled={false}
								secure
								inputStyle={{
									width: '10px !important',
									height: '10px',
									fontSize: '2rem',
									borderRadius: 4,
									border: muiTheme.palette.divider
								}}
								className="otp-filed"
								cy-data="reset-psd-otp-input"
							/>
							<Typography className="password-text" width="100%">
								Type new password
							</Typography>

							<TextField
								fullWidth
								className="input-filetext"
								label="At least 8 characters"
								type="password"
								name="newPassword"
								value={formik.values.newPassword}
								onChange={formik.handleChange}
								error={
									formik.touched.newPassword &&
									Boolean(formik.errors.newPassword)
								}
								helperText={
									formik.touched.newPassword && formik.errors.newPassword
								}
								InputLabelProps={{
									style: {
										color: muiTheme.palette.text.auth.signup100,
										fontSize: '14px'
									}
								}}
								cy-data="reset-psd-new-psd"
							/>
							<Typography className="password-text" width="100%">
								Re-type new password
							</Typography>
							<TextField
								fullWidth
								className="input-filetext"
								label="At least 8 characters"
								type="password"
								name="confirmNewPassword"
								value={formik.values.confirmNewPassword}
								onChange={formik.handleChange}
								error={
									formik.touched.confirmNewPassword &&
									Boolean(formik.errors.confirmNewPassword)
								}
								helperText={
									formik.touched.confirmNewPassword &&
									formik.errors.confirmNewPassword
								}
								InputLabelProps={{
									style: {
										color: muiTheme.palette.text.auth.signup100,
										fontSize: '14px'
									}
								}}
								cy-data="reset-psd-confirm-psd"
							/>
							<Button
								variant="contained"
								className={
									partnerAuth ? 'xanadu-reset_passbutton' : 'reset_passbutton'
								}
								sx={{ height: 60 }}
								type="submit"
								value="submit"
								fullWidth
								cy-data="reset-psd-submit-btn"
							>
								Reset Password Now
							</Button>
							<Button
								sx={{
									margin: '1em 0',
									color: muiTheme.palette.text.auth.login100,
									fontSize: '12px',
									'&:hover': {
										backgroundColor: 'rgba(133, 19, 247, 0.10)',
										boxShadow: ' 0px 18px 35px rgba(152, 64, 210, 0.15)'
									}
								}}
								onClick={handleResetPass}
								className="link-button"
								fullWidth
								cy-data="reset-psd-cancel-btn"
							>
								Go Back
							</Button>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	);
}
