import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeSnack } from '../../Redux/Action/loadingErrMsgAction';

const Alert = React.forwardRef((props, ref) => (
	<MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

/**
 * SnackbarComponent - A component that displays a snackbar notification.
 *
 * This component uses the `useSelector` hook to access the `openSnack`, `snackMessage`, and `snackColor` 
 * properties from the Redux state managed by `lemReducer`. It also uses the `useDispatch` hook to dispatch 
 * actions to close the snackbar.
 *
 * @component
 * @returns {JSX.Element} The rendered Snackbar component.
 */

function SnackbarComponent() {
	const { openSnack, snackMessage, snackColor } = useSelector(
		(state) => state.lemReducer
	);
	const dispatch = useDispatch();

	return (
		<Snackbar
			open={openSnack}
			autoHideDuration={4000}
			anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
			onClose={() => dispatch(closeSnack())}
			cy-data="snackBar"
		>
			<Alert
				onClose={() => dispatch(closeSnack())}
				severity={snackColor}
				sx={{ width: '100%' }}
				cy-data="alert"
			>
				{snackMessage}
			</Alert>
		</Snackbar>
	);
}

export default SnackbarComponent;
