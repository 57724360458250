/**
 * This component is used to clone a public repository from GitHub to qBraid Lab.
 * @module GitHubIntegration
 * @category Integrations
 * @subcategory GitHub
 * @requires react
 * @copyright 2024 qBraid Development Team
 */
import {
	useCallback, useEffect, useState, useRef 
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { axiosClient, LAB_URL, email } from '../../../utils/axiosClient';

function GitHubIntegration() {
	const navigate = useNavigate();
	const { search } = useLocation();
	// eslint-disable-next-line unused-imports/no-unused-vars
	const [showModal, setShowModal] = useState(false);
	const gitHubUrl = new URLSearchParams(search).get('gitHubUrl');
	const envId = new URLSearchParams(search).get('envId');
	let redirectUrl = new URLSearchParams(search).get('redirectUrl');
	const userRef = useRef('');
	const repoNameRef = useRef('');
	const splitGitUrlRef = useRef([])
	const envInstallMsgRef = useRef('');

	if (gitHubUrl) {
		const splitUrl = gitHubUrl?.split('/');
		repoNameRef.current = splitUrl?.pop()?.replace(/(.git$)/, '');
		userRef.current = splitUrl[splitUrl.length - 1];
		splitGitUrlRef.current = splitUrl;
	}
	const checkForRedirect = async () => {
		if (redirectUrl) {
			// Strip leading slashes
			redirectUrl = redirectUrl.replace(/^\/+/, '');
			// Check if file exists on github
			try {
				const response = await fetch(
					`https://api.github.com/repos/${userRef.current}/${repoNameRef.current}/contents/${redirectUrl}`
				);
				if (!response.ok) {
					return await Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: 'The file or folder specified in the redirect url was not found. Your lab may not open up to the correct file or folder. Do you want to proceed anyway?',
						confirmButtonColor: 'var(--brand-color-1)',
						showCancelButton: true,
						showConfirmButton: true,
						reverseButtons: true,
						confirmButtonText: 'Proceed anyway',
						cancelButtonColor: 'var(--brand-color-2)',
						cancelButtonText: 'Go back'
					}).then((result) => {
						console.log(result, 'result');
						if (result.isDismissed) {
							navigate(window.history.go(-1));
						} else if (result.isConfirmed) {
							return true;
						}
					});
				}
				return true;
			} catch (error) {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: `Error: ${error.message}`,
					confirmButtonColor: 'var(--brand-color-1)',
					allowOutsideClick: true
				});
				return false;
			}
		}
		return true;
	};

	const fetchGitRepo = useCallback(async () => {
		Swal.fire({
			cancelButtonText: 'Go to Dashboard',
			allowOutsideClick: false,
			imageWidth: '20%',
			imageUrl: 'https://cdn-icons-png.flaticon.com/512/25/25231.png',
			reverseButtons: true,
			title: 'Get ready to be launched to qBraid Lab.😀',
			text: `Hold on tight...`,
			progressSteps: ['1', '2', '3'],
			currentProgressStep: 0,
			progressStepsDistance: '20px',
			timerProgressBar: true,
			timer: 3000
		}).then( () => {
			Swal.showLoading();
			Swal.fire({
				icon: 'info',
				title: 'Checking environment...',
				text: envInstallMsgRef.current,
				showConfirmButton: false,
				progressSteps: ['1', '2', '3'],
				currentProgressStep: 1,
				timer: 4000,
				timerProgressBar: true
			}).then( async () => {
				Swal.showLoading();
				let data = await fetch(
					`https://api.github.com/repos/${userRef.current}/${repoNameRef.current}`
				);
				// convert data to json
				data = await data.json();

				if (data.id) {
					try {
						const result = await axiosClient().post(
							'/api/lab/github/clone',
							{
								repoUrl: gitHubUrl,
								labUrl: LAB_URL
							},
							{
								// used to stop the failed response going to the catch block
								validateStatus: (status) => status <= 500
							}
						);
						Swal.hideLoading();
						if(result.status >= 400) {
							console.log(result);
							fireRepoCloneFailedAlert("Failed to clone GitHub repository", `Request failed: ${result?.data?.message}`);
						}
						else { 
							Swal.fire({
								icon: 'success',
								title: 'Systems calibrated ... all systems go🚀 ',
								showConfirmButton: false,
								progressSteps: ['1', '2', '3'],
								currentProgressStep: 2,
								timer: 3000,
								timerProgressBar: true
							}).then(() =>
								redirectUrl
									? window.location.replace(
										`${result.data.redirect}/${redirectUrl}`
									)
									: window.location.replace(result.data.redirect));
						}
					} catch (err) {
						Swal.hideLoading();
						fireRepoCloneFailedAlert("Cloning Request Failed", `Request failed to clone GitHub repository: ${err.message}`);
					}
				} else {
					Swal.hideLoading();
					fireRepoCloneFailedAlert("Cloning Request Failed", `Request failed to clone GitHub repository: ${data?.message}`);
				}
			});
		});
	});

	const fireRepoCloneFailedAlert = useCallback((title, message) => {
		Swal.fire({
			icon: 'error',
			title: title,
			text: message,
			progressSteps: ['1', '2', '3'],
			currentProgressStep: 2,
			confirmButtonColor: 'var(--brand-color-1)',
			showConfirmButton: true,
			confirmButtonText: 'Proceed anyway',
			showCancelButton: true,
			cancelButtonText: 'Go back',
			cancelButtonColor: 'var(--brand-color-2)',
		}).then((result) =>{
			if (result.isConfirmed) {
				window.location.href = LAB_URL;
			} else if (result.isDismissed){
				navigate(window.history.go(-1));
			}
		});
	});

	const fireUserSignInAlert = useCallback(() => {
		Swal.fire({
			allowOutsideClick: false,
			showCancelButton: true,
			reverseButtons: true,
			cancelButtonText: 'Go back',
			confirmButtonColor: 'var(--brand-color-1)',
			title: 'You need to sign in to clone a repository',
			text: 'Redirect back to the dashboard',
			icon: 'info'
		}).then((result) => {
			if (result.isDismissed) {
				// navigate(window.history.go(-1));
			}
		});
	});

	const handleEnvInstall = useCallback(async () => {
		if (!envId) {
			envInstallMsgRef.current = 'No environment detected.';
			return;
		}
		// call the qbraid api to check the validity of the environment
		try {
			const envValidResponse = await axiosClient().get(
				
				`/api/environments/valid?envSlug=${envId}`,
				{
					// used to stop the failed response going to the catch block
					validateStatus: (status) => status <= 500,
					// user-agent is used to differentiate between frontend and github README requests
					headers: {
						'x-custom-user-agent': 'qBraid/account'
					}
				}
			);
			if (envValidResponse.status >= 400) {
				console.error(envValidResponse);
				// catch requests that fail with 4xx, 5xx status codes
				const message =
					envValidResponse.data.frontendMsg || 'An unknown error occurred.';

				// global message populated and shown to user
				envInstallMsgRef.current = `Message: ${message}`;
				return;
			}

			// valid env detected, proceed with installation
			const installResponse = await axiosClient().post(
				`/api/environments/install/${envId}`,
				{ slug: envId },
				{
					// used to stop the failed response going to the catch block
					validateStatus: (status) => status <= 500
				}
			);
			if (installResponse.status >= 400) {
				console.error(installResponse);
			}
			envInstallMsgRef.current = `Message: ${installResponse?.data?.message}`;
		} catch (error) {
			console.error(error);
			envInstallMsgRef.current = `Message: ${error?.response?.message}`;
		}
	});

	const handleGitHubClone = async () => {
		if (!gitHubUrl) return;
		if (!email) {
			fireUserSignInAlert();
			return; // Exit early
		}
		const redirect = await checkForRedirect();
		if (redirect) {
			try {
				await handleEnvInstall();
				await fetchGitRepo();
			} catch (error) {
				console.error(error);
			}
		}
	};

	useEffect(() => {
		if (gitHubUrl && !email) {
			setShowModal(true);
		} else {
			handleGitHubClone();
		}
	}, [gitHubUrl, envId, email]);

	return null;
}

export default GitHubIntegration;