import { LEM_SNACK_OPEN, LEM_SNACK_CLOSE } from '../Types/loadingErrMsgTypes';

export const apiDown = (payload) => ({
	type: LEM_SNACK_OPEN,
	payload: { message: payload, color: 'error' }
});

export const closeSnack = () => ({
	type: LEM_SNACK_CLOSE
});

export const setSnackbarMsg = (payload) => ({
	type: LEM_SNACK_OPEN,
	payload
});
