export const FETCH_ORGLIST_LOADING = 'FETCH_ORGLIST_LOADING';
export const FETCH_ORGLIST_SUCCESS = 'FETCH_ORGLIST_SUCCESS';
export const FETCH_ORGLIST_FAILED = 'FETCH_ORGLIST_FAILED';
export const ORGLIST_LOADING_STOP = 'ORGLIST_LOADING_STOP';

export const ADD_TO_ACTIVE_USER_LOADING_ORGS =
	'ADD_TO_ACTIVE_USER_LOADING_ORGS';
export const REMOVE_FROM_ACTIVE_USER_LOADING_ORGS =
	'REMOVE_FROM_ACTIVE_USER_LOADING_ORGS';
export const FETCH_ORG_USER_LIST_SUCCESS = 'FETCH_ORG_USER_LIST_SUCCESS';

export const SET_SELECTED_ORG = 'SET_SELECTED_ORG';
export const SET_SELECTED_ORG_CURRENT_USER_ROLE =
	'SET_SELECTED_ORG_CURRENT_USER_ROLE';
export const SET_SELECTED_ORG_CURRENT_USER_DEDUCTION_REQUEST =
	'SET_SELECTED_ORG_CURRENT_USER_DEDUCTION_REQUEST';
export const REMOVE_ORG_USERS_FROM_LIST = "REMOVE_ORG_USERS_FROM_LIST";

export const SET_PAGINATION_DETAILS_FOR_ORG_USERS = "SET_PAGINATION_DETAILS_FOR_ORG_USERS"

export const CREATE_ORG_LOADING = 'CREATE_ORG_LOADING';
export const CREATE_ORG_LOADING_STOP = 'CREATE_ORG_LOADING_STOP';
export const CREATE_ORG_SUCCESS = 'CREATE_ORG_SUCCESS';
export const CREATE_ORG_FAILED = 'CREATE_ORG_FAILED';
export const SET_USER_INVITE_LIST = 'SET_USER_INVITE_LIST';
export const RESET_ORG_STATE = 'RESET_ORG_STATE';

export const SET_MAIN_TAB_VALUE = 'SET_MAIN_TAB_VALUE';
export const SET_PURCHASE_TAB_VALUE = 'SET_PURCHASE_TAB_VALUE';

export const SET_ORGANIZATION_TRANSACTION_SUCCESS = "SET_ORGANIZATION_TRANSACTION_SUCCESS"
export const SET_ORGANIZATION_TRANSACTION_LOADING = "SET_ORGANIZATION_TRANSACTION_LOADING"
export const SET_ORGANIZATION_TRANSACTION_FAILURE = "SET_ORGANIZATION_TRANSACTION_FAILURE"
