import { LEM_SNACK_OPEN, LEM_SNACK_CLOSE } from '../Types/loadingErrMsgTypes';

const initialState = {
	openSnack: false,
	snackMessage: '',
	snackColor: 'success'
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case LEM_SNACK_OPEN:
			return {
				...state,
				openSnack: true,
				snackMessage: action.payload.message,
				snackColor: action.payload.color
			};
		case LEM_SNACK_CLOSE:
			return {
				...state,
				openSnack: false,
				snackMessage: ''
			};
		default:
			return state;
	}
}
