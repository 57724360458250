import Swal from 'sweetalert2';
import {
	FETCH_PRICES_FAILED,
	FETCH_PRICES_LOADING,
	FETCH_PRICES_SUCCESS,
	GET_USER_CREDITS_FAILED,
	GET_USER_CREDITS_LOADING,
	GET_USER_CREDITS_SUCCESS,
	GET_USER_BILLING_FAILED,
	GET_USER_BILLING_LOADING,
	GET_USER_BILLING_SUCCESS,
	CREATE_SESSION_FAILED,
	CREATE_SESSION_LOADING,
	CREATE_SESSION_SUCCESS,
	CANCEL_USER_SUB_FAILED,
	CANCEL_USER_SUB_LOADING,
	CANCEL_USER_SUB_SUCCESS,
	UPGRADE_USER_SUB_FAILED,
	UPGRADE_USER_SUB_LOADING,
	UPGRADE_USER_SUB_SUCCESS,
	TRANSFER_CREDIT_FAILED,
	TRANSFER_CREDIT_SUCCESS,
	TRANSFER_CREDIT_RESET
} from '../Types/billingTypes';
import { axiosClient } from '../../utils/axiosClient';
import { apiDown, setSnackbarMsg } from './loadingErrMsgAction';

export const fetchPricesList = (productType) => async (dispatch) => {
	dispatch(FetchPricesListLoading());
	try {
		const response = await axiosClient().post('/api/billing/products/new', {
			productType: productType
		});
		return dispatch(FetchPricesListSuccess(response.data, productType));
	} catch (err) {
		if (err?.response?.status === 500) {
			dispatch(apiDown('API is down !'));
		}
		return dispatch(FetchPricesListFailed());
	}
};

export const createCheckoutSession =
	(priceId, mode, orgId) => async (dispatch) => {
		dispatch(CreateSessionLoading());
		let url = window.location.href;
		if (url.slice(-1) !== '/') {
			url = url.concat('/');
		}
		let checkoutPayload = {
			price_id: priceId,
			callback_url: url,
			mode: mode
		};
		if (orgId !== undefined) {
			checkoutPayload = { ...checkoutPayload, orgId: orgId };
		}

		try {
			const response = await axiosClient().post(
				'/api/billing/create-checkout-session',
				checkoutPayload
			);
			return dispatch(CreateSessionSuccess(response.data));
		} catch (err) {
			if (err?.response?.status === 500) {
				dispatch(apiDown('API is down !'));
			}
			return dispatch(CreateSessionFailed());
		}
	};

export const getUserCredits = () => async (dispatch) => {
	dispatch(GetUserCreditsLoading());
	try {
		const response = await axiosClient().get(
			'/api/billing/credits/get-user-credits'
		);
		return dispatch(GetUserCreditsSuccess(response.data));
	} catch (err) {
		if (err?.response?.status === 500) {
			dispatch(apiDown('API is down !'));
		}
		return dispatch(GetUserCreditsFailed());
	}
};

export const getUserBilling = () => async (dispatch) => {
	dispatch(GetUserBillingLoading());
	try {
		const response = await axiosClient().get('/api/billing/get-user-billing');
		return dispatch(GetUserBillingSuccess(response.data));
	} catch (err) {
		return dispatch(GetUserBillingFailed());
	}
};

export const downgradeUserSub = (priceId) => async (dispatch) => {
	dispatch(DowngradeUserSubLoading());
	try {
		const response = await axiosClient().post(
			'/api/billing/subscription/downgrade',
			{
				priceId: priceId
			}
		);
		Swal.fire({
			position: 'center',
			icon: 'success',
			title: 'Downgraded Subscription Successfully',
			text: 'The price difference will prorate and be added to your next invoice.',
			showConfirmButton: false,
			timer: 3000
		});
		dispatch(fetchPricesList());
		dispatch(getUserBilling());
		return dispatch(DowngradeUserSubLoading(response.data));
	} catch (err) {
		return dispatch(DowngradeUserSubFailed());
	}
};

export const cancelUserSub = () => async (dispatch) => {
	dispatch(CancelUserSubLoading());
	try {
		const response = await axiosClient().post(
			'/api/billing/subscription/cancel'
		);
		Swal.fire({
			position: 'center',
			icon: 'success',
			title: 'Cancelled Subscription Successfully',
			showConfirmButton: false,
			timer: 3000
		});
		dispatch(fetchPricesList());
		dispatch(getUserBilling());
		return dispatch(CancelUserSubSuccess(response.data));
	} catch (err) {
		return dispatch(CancelUserSubFailed());
	}
};

export const upgradeUserSub = (priceId) => async (dispatch) => {
	dispatch(UpgradeUserSubLoading());
	try {
		const response = await axiosClient().post(
			'/api/billing/subscription/upgrade',
			{
				priceId: priceId
			}
		);
		window.location.reload();
		return dispatch(UpgradeUserSubSuccess(response.data));
	} catch (err) {
		return dispatch(UpgradeUserSubFailed());
	}
};

export const transferCredits = (payload) => async (dispatch) => {
	try {
		const response = await axiosClient().post(
			'api/orgs/users/credits/transfer',
			{
				orgEmail: payload.orgEmail,
				transferEmail: payload.recipientEmail,
				credits: payload.credit
			}
		);
		if (response.status === 200) {
			dispatch(
				setSnackbarMsg({
					message: 'Transfer Successful',
					color: 'success'
				})
			);
			return dispatch(TransferCreditSuccess(response.data.message));
		}
		dispatch(
			setSnackbarMsg({
				message: 'Transfer unsuccessful',
				color: 'error'
			})
		);
		return dispatch(TransferCreditFailed(response.data.message));
	} catch (err) {
		return dispatch(TransferCreditFailed(err.response.data.message));
	}
};

export const FetchPricesListLoading = (payload) => ({
	type: FETCH_PRICES_LOADING,
	payload
});

export const FetchPricesListSuccess = (payload) => ({
	type: FETCH_PRICES_SUCCESS,
	payload
});

export const FetchPricesListFailed = (payload) => ({
	type: FETCH_PRICES_FAILED,
	payload
});

export const CreateSessionLoading = (payload) => ({
	type: CREATE_SESSION_LOADING,
	payload
});

export const CreateSessionSuccess = (payload) => ({
	type: CREATE_SESSION_SUCCESS,
	payload
});

export const CreateSessionFailed = (payload) => ({
	type: CREATE_SESSION_FAILED,
	payload
});

export const GetUserCreditsLoading = (payload) => ({
	type: GET_USER_CREDITS_LOADING,
	payload
});

export const GetUserCreditsSuccess = (payload) => ({
	type: GET_USER_CREDITS_SUCCESS,
	payload
});

export const GetUserCreditsFailed = (payload) => ({
	type: GET_USER_CREDITS_FAILED,
	payload
});

export const GetUserBillingLoading = (payload) => ({
	type: GET_USER_BILLING_LOADING,
	payload
});

export const GetUserBillingSuccess = (payload) => ({
	type: GET_USER_BILLING_SUCCESS,
	payload
});

export const GetUserBillingFailed = (payload) => ({
	type: GET_USER_BILLING_FAILED,
	payload
});

export const DowngradeUserSubLoading = (payload) => ({
	type: CANCEL_USER_SUB_LOADING,
	payload
});

export const DowngradeUserSubSuccess = (payload) => ({
	type: CANCEL_USER_SUB_SUCCESS,
	payload
});

export const DowngradeUserSubFailed = (payload) => ({
	type: CANCEL_USER_SUB_FAILED,
	payload
});

export const CancelUserSubLoading = (payload) => ({
	type: CANCEL_USER_SUB_LOADING,
	payload
});

export const CancelUserSubSuccess = (payload) => ({
	type: CANCEL_USER_SUB_SUCCESS,
	payload
});

export const CancelUserSubFailed = (payload) => ({
	type: CANCEL_USER_SUB_FAILED,
	payload
});

export const UpgradeUserSubLoading = (payload) => ({
	type: UPGRADE_USER_SUB_LOADING,
	payload
});

export const UpgradeUserSubSuccess = (payload) => ({
	type: UPGRADE_USER_SUB_SUCCESS,
	payload
});

export const UpgradeUserSubFailed = (payload) => ({
	type: UPGRADE_USER_SUB_FAILED,
	payload
});

export const TransferCreditSuccess = (payload) => ({
	type: TRANSFER_CREDIT_SUCCESS,
	payload
});

export const TransferCreditFailed = (payload) => ({
	type: TRANSFER_CREDIT_FAILED,
	payload
});

export const TransferCreditReset = () => ({
	type: TRANSFER_CREDIT_RESET
});
